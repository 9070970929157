import { useEffect, useState } from 'react';

function useSessionToggle(queryParamKey) {
  const [isToggleOn, setIsToggleOn] = useState(
    sessionStorage.getItem(queryParamKey) === 'true',
  );
  const clientIDValue = document.getElementById('client-id');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const toggleStateFromUrl = urlParams.get(queryParamKey);

    if (
      toggleStateFromUrl === 'true' ||
      (clientIDValue && clientIDValue?.value !== 'website')
    ) {
      setIsToggleOn(true);
      sessionStorage.setItem(queryParamKey, true);
    }
  }, [queryParamKey]);

  return isToggleOn;
}

export { useSessionToggle };
